//====================================================
//  Function: Tabs
//====================================================
function fn_components_tabs(){
	if ($( '.js-tabs-list-filter' ).length){

		// var filterClassArray = [];

		$( '.js-tabs-list-filter .js-tabs-list-item' ).click(function() {
			var inst = $(this);
			var thisId = inst.attr( 'data-id' );
			var thisTabsList = $(this).closest( '.js-tabs-list-filter' );

			// if(thisId == 'undefined'){
			// 	return false;
			// }

			if(thisId == 'all'){
				// $( '.js-tabs-list-item.mixitup-control-active' ).removeClass( 'mixitup-control-active' );
				// thisTabsList.find( '.js-tabs-list-item.mixitup-control-active' ).removeClass( class_active );
				// inst.addClass( class_active );
				// $( '.js-tabs-list-filter .js-tabs-list-item[data-id="all"] .tabs-list-text' ).trigger('click');
				
				$( '.js-tabs-list-filter .js-tabs-list-item[data-id="all"]' ).addClass( class_active );
				thisTabsList.find( '.js-tabs-sublist-group.' + class_active + '' ).removeClass( class_active );
				// mixer.show();
				// return false;
			} else {
				thisTabsList.find( '.js-tabs-list-item[data-id="all"]' ).removeClass( class_active );

				if (!inst.hasClass( "mixitup-control-active" )){
					// inst.addClass( class_active );
					// thisTabsList.find( '.js-tabs-sublist-group.' + class_active + '' ).removeClass( class_active );
					thisTabsList.find( '.js-tabs-sublist-group[data-id="'+ thisId + '"]' ).addClass( class_active );
				} else {
					// inst.removeClass( class_active );
					// thisTabsList.find( '.js-tabs-list-item.' + class_active + '' ).removeClass( class_active );
					thisTabsList.find( '.js-tabs-sublist-group[data-id="'+ thisId + '"]' ).find('.js-tabs-sublist-reset').trigger('click');
					thisTabsList.find( '.js-tabs-sublist-group[data-id="'+ thisId + '"]' ).removeClass( class_active );
					// thisTabsList.find( '.js-tabs-sublist-group[data-id="'+ thisId + '"]' ).removeClass( class_active );
					// mixer.show();
					// $( '.js-tabs-list-filter .js-tabs-list-item[data-id="all"] .tabs-list-text' ).trigger('click');

				}
				
			}

			

			// if (!thisTabsList.find( '.js-tabs-list-item.is-active' ).length){
			// 	$( '.js-tabs-list-filter .js-tabs-list-item[data-id="all"]' ).addClass( class_active );
			// 	mixer.show();
			// }

			// var getFilterClass = inst.attr('data-filter-class');
			// if (filterClassArray.includes(getFilterClass)){
			// 	remove(filterClassArray, getFilterClass);
			// } else {
			// 	filterClassArray.push(getFilterClass);
			// }

			// var filteredString =  filterClassArray.join(', ');

			// console.log(filteredString);

		});

		// $( '.js-tabs-list-filter .js-tabs-sublist-item' ).click(function() {
		// 	var inst = $(this);
		// 	// var getFilterClass = inst.attr('data-filter-class');
		// 	// var getFilterGroupClass = inst.closest('.js-tabs-sublist-group').attr('data-filter-group');



		// 	if (!inst.hasClass( class_active )){
		// 		inst.addClass( class_active );
		// 	} else {
		// 		inst.removeClass( class_active );
		// 	}
			
		
		// 	// if (!inst.closest('.js-tabs-sublist-group').hasClass( class_active )){
		// 	// 	filterClassArray.push(getFilterGroupClass);
		// 	// } else{
		// 	// 	remove(filterClassArray, getFilterGroupClass);
		// 	// }
			
		// 	// if (filterClassArray.includes(getFilterClass)){
		// 	// 	remove(filterClassArray, getFilterClass);
		// 	// } else {
		// 	// 	filterClassArray.push(getFilterClass);
		// 	// }


		// 	if (inst.closest('.js-tabs-sublist-group').find('.js-tabs-sublist-item.is-active').length == 0){
		// 		console.log("prázdne");
		// 	}
			
			
		// });

		// $( '.js-tabs-list-filter .js-tabs-sublist-item' ).click(function() {
		// 	var inst = $(this);
		// 	var getFilterClass = inst.attr('data-filter-class');
		// 	var getFilterGroupClass = inst.closest('.js-tabs-sublist-group').attr('data-filter-group');



		// 	if (!inst.hasClass( class_active )){
		// 		inst.addClass( class_active );
		// 	} else {
		// 		inst.removeClass( class_active );
		// 	}
			
		
		// 	if (!inst.closest('.js-tabs-sublist-group').hasClass( class_active )){
		// 		filterClassArray.push(getFilterGroupClass);
		// 	} else{
		// 		remove(filterClassArray, getFilterGroupClass);
		// 	}
			
		// 	if (filterClassArray.includes(getFilterClass)){
		// 		remove(filterClassArray, getFilterClass);
		// 	} else {
		// 		filterClassArray.push(getFilterClass);
		// 	}


		// 	if (inst.closest('.js-tabs-sublist-group').find('.js-tabs-sublist-item.is-active').length == 0){
		// 		filterClassArray.push(getFilterGroupClass);
		// 	}

		// 	var filteredString =  filterClassArray.join(', ');

		// 	console.log(filteredString);
		// });

	}

	function remove(arr, what) {
		var found = arr.indexOf(what);

		while (found !== -1) {
			arr.splice(found, 1);
			found = arr.indexOf(what);
		}
	}

	if ($( '.js-tabs-group' ).length){
		$( '.js-tabs-group .js-tabs-list-item' ).click(function() {
			var inst = $(this);
			var thisId = inst.attr( 'data-id' );
			var thisTabs = $(this).closest( '.js-tabs-group' );

			if(thisId == 'undefined'){
				return false;
			}
			
			if (!inst.hasClass( class_active )){
				thisTabs.find( '.js-tabs-list-item.' + class_active + '' ).removeClass( class_active );
				$(this).closest( '.js-tabs-list-item').addClass( class_active );
				thisTabs.find( '.js-tabs-content .js-tabs-content-item.' + class_active + '' ).removeClass( class_active );
				thisTabs.find( '.js-tabs-content .js-tabs-content-item[data-id="'+ thisId + '"]' ).addClass( class_active );
			}
		});
	}
}
