// --- GLOBAL VARIABLES --- //
var $window = $(window);
var $document = $(document);
var $header = $( '.header' );
var bp_small = 770;
var bp_medium = 992;
var class_active = 'is-active';
var class_last = 'is-last';
var class_disabled = 'is-disabled';
var $this = null;
var $containers = $( 'html,body' );


// --- COMPONENTS, PARTS --- //
$( document ).ready( function(){
	// ---  COMPONENTS --- //
	fn_components_accordion();
	fn_components_remove();
	fn_components_modal();
	fn_components_fullscreen_navigation();
	fn_components_gallery();
	fn_components_slider();
	fn_components_tabs();
	fn_components_redirect_url();
	fn_components_mixItUp();

	// ---  PARTS --- //
	fn_parts_placeholder();
	fn_parts_dropdown();
	fn_parts_show_hidden();
	fn_parts_navigation_scroll_resize();
	fn_parts_scroll_to();

});


// --- SCROLL EVENT --- //
$( document ).scroll( function () {
	fn_parts_navigation_scroll_resize();
});

// --- RESIZE EVENT --- //
$( window ).resize( function(){
	fn_parts_navigation_scroll_resize();
});

// --- LOAD EVENT --- //
$( window ).bind( 'load' , function () {
	setTimeout(function(){
		if ($( '#cookies-bar' ).length){
			$( '#cookies-bar' ).addClass( class_active );
		}
	}, 2000);

	if ($( '#contact-map' ).length){
		fn_components_google_map();
	}

	fn_parts_dots();
	// msieVersion();
	// safariVersion();

});

function msieVersion() {

    var ua = window.navigator.userAgent;
    var msie = ua.indexOf("MSIE ");

    if (msie > 0 || !!navigator.userAgent.match(/Trident.*rv\:11\./)) {
    	$('body').addClass( 'ie' );
    }

    return false;
}

function safariVersion(){
	if ( /^((?!chrome|android).)*safari/i.test(navigator.userAgent)) {
    	$('body').addClass( 'safari' );
	}
}