/* ==========================================================================
 *  Function: MixItUp
 * ======================================================================= */
var containerEl;
var mixer;
function fn_components_mixItUp(){
	if ($( '.js-filter-group' ).length){
		containerEl = document.querySelector( '.js-filter-group' );
		mixer = mixitup(containerEl, {
			multifilter: {
				enable: true 
			},
			callbacks: {
				onMixStart: function(state, futureState) {
					if ($(window).width() < bp_small){
						$('.js-active-class-hide').trigger('click');
					}
					console.log(futureState.activeFilter.selector);
				},
				onMixEnd: function(state, futureState) {
					// if (!$( '.js-tabs-list-filter .js-tabs-list-item.mixitup-control-active' ).length){
					// 	// $( '.js-tabs-list-item.mixitup-control-active' ).removeClass( 'mixitup-control-active' );
					// 	$( '.js-tabs-list-filter .js-tabs-list-item[data-id="all"]' ).addClass( class_active );
					// }
				}
			}
			// controls: {
			// 	enable: true,
			// 	live: true,
			// 	toggleDefault: 'all',
			// 	// toggleLogic: 'or'
			// },
			// multifilter: {
			// 	enable: true
			// }
		});
	}
}
