
//====================================================
//  Function: Marquee
//====================================================
function fn_components_marquee(){
	if ($( '.js-marquee' ).length && $(window).width() > bp_small) {
		 var $mq = $( '.js-marquee' ).marquee({
		    //speed in milliseconds of the marquee
		    duration: 15000,
		    startVisible : true,
		    //gap in pixels between the tickers
		    gap: 0,
		    //time in milliseconds before the marquee will start animating
		    delayBeforeStart: 0,
		    //'left' or 'right'
		    direction: 'left',
			pauseOnHover : true,
		    //true or false - should the marquee be duplicated to show an effect of continues flow
		    duplicated: true
		});	

		$('.js-marquee').marquee('pointer').mouseover(function () {
			// $mq.marquee('pause');
		}).mouseout(function () {
			// $(this).trigger('start');
		}).mousemove(function (event) {
			if ($(this).data('drag') == true) {
				this.scrollLeft = $(this).data('scrollX') + ($(this).data('x') - event.clientX);
			}
		}).mousedown(function (event) {
			$(this).data('drag', true).data('x', event.clientX).data('scrollX', this.scrollLeft);
		}).mouseup(function () {
			$(this).data('drag', false);
		});

	}
}

