//====================================================
//  Function: Slider
//====================================================
function fn_components_slider(){
	fn_realization_slider();
	fn_classic_slider();
	fn_glass_slider();
	fn_category_slider();
	fn_blog_slider();
}

function fn_realization_slider(){
	if ($( '.js-slider-realization' ).length) {
		$( '.js-slider-realization' ).slick({
			infinite: true,
			dots: false,
			slidesToShow: 1,
			slidesToScroll: 1,
			arrows: true,
			autoplay: true,
			autoplaySpeed: 3000,
			cssEase: 'ease',
			// fade: true,
			speed: 500,
			variableWidth: false,
			nextArrow: '<span class="slick-arrows slick-next"><i class="icon icon-arrow-right"></i></span>',
			prevArrow: '<span class="slick-arrows slick-prev"><i class="icon icon-arrow-left"></i></span>',
			mobileFirst: true,
			responsive: [
				{
					breakpoint: 1400,
					settings: {
						slidesToShow: 4,
					}
				},
				{
					breakpoint: 1220,
					settings: {
						slidesToShow: 3,
					}
				},
				{
					breakpoint: 992,
					settings: {
						slidesToShow: 3,
					}
				},
				{
					breakpoint: 770,
					settings: {
						slidesToShow: 2,
					}
				},
				{
					breakpoint: 570,
					settings: {
						slidesToShow: 2,
					}
				},
				{
					breakpoint: 400,
					settings: {
						slidesToShow: 1,
					}
				},
				{
					breakpoint: 200,
					settings: {
						slidesToShow: 1,
					}
				}
			]
		});

		// $('.js-realization-prev').bind( 'click' ,function(){
		// 	$( '.js-slider-realization' ).slick('slickPrev');
		// });

		// $('.js-realization-next').bind( 'click' ,function(){
		// 	$( '.js-slider-realization' ).slick('slickNext');
		// });
		
	}
}


function fn_classic_slider(){
	if ($( '.js-slider-classic' ).length) {
		$( '.js-slider-classic' ).slick({
			infinite: true,
			dots: true,
			slidesToShow: 1,
			slidesToScroll: 1,
			arrows: false,
			autoplay: false,
			autoplaySpeed: 3000,
			cssEase: 'ease',
			// fade: true,
			speed: 500,
			variableWidth: false,
			nextArrow: '<span class="slick-arrows slick-next"><i class="icon icon-arrow-right"></i></span>',
			prevArrow: '<span class="slick-arrows slick-prev"><i class="icon icon-arrow-left"></i></span>',
			mobileFirst: true
		});
	}
}


function fn_category_slider(){
	if ($( '.js-slider-category' ).length) {
		$( '.js-slider-category' ).slick({
			infinite: true,
			dots: false,
			slidesToShow: 1,
			slidesToScroll: 1,
			arrows: true,
			autoplay: false,
			autoplaySpeed: 3000,
			cssEase: 'ease',
			speed: 500,
			variableWidth: false,
			nextArrow: '<span class="slick-arrows slick-next"><i class="icon icon-arrow-right"></i></span>',
			prevArrow: '<span class="slick-arrows slick-prev"><i class="icon icon-arrow-left"></i></span>',
			mobileFirst: true
		});
	}
}

function fn_blog_slider(){
	if ($( '.js-slider-blog' ).length) {
		$( '.js-slider-blog' ).slick({
			infinite: true,
			dots: false,
			slidesToShow: 1,
			slidesToScroll: 1,
			arrows: false,
			autoplay: false,
			autoplaySpeed: 3000,
			cssEase: 'ease',
			// fade: true,
			speed: 500,
			variableWidth: false,
			nextArrow: '<span class="slick-arrows slick-next"><i class="icon icon-arrow-right"></i></span>',
			prevArrow: '<span class="slick-arrows slick-prev"><i class="icon icon-arrow-left"></i></span>',
			mobileFirst: true,
			responsive: [
				{
					breakpoint: 1400,
					settings: {
						slidesToShow: 4,
						dots: false
					}
				},
				{
					breakpoint: 1220,
					settings: {
						slidesToShow: 3,
						dots: false
					}
				},
				{
					breakpoint: 992,
					settings: {
						slidesToShow: 3,
						dots: false
					}
				},
				{
					breakpoint: 770,
					settings: {
						slidesToShow: 2,
						dots: false
					}
				},
				{
					breakpoint: 570,
					settings: {
						slidesToShow: 2,
						dots: true
					}
				},
				{
					breakpoint: 400,
					settings: {
						slidesToShow: 1,
						dots: true
					}
				},
				{
					breakpoint: 200,
					settings: {
						slidesToShow: 1,
						dots: true
					}
				}
			]
		});
	}
}

function fn_glass_slider(){
	if ($( '.js-slider-glass' ).length) {
		$( '.js-slider-glass' ).slick({
			infinite: true,
			dots: false,
			slidesToShow: 1,
			slidesToScroll: 1,
			arrows: true,
			autoplay: true,
			autoplaySpeed: 3000,
			cssEase: 'ease',
			centerMode: true,
			focusOnSelect: true,
			// fade: true,
			speed: 500,
			variableWidth: true,
			nextArrow: '<span class="slick-arrows slick-next"><i class="icon icon-arrow-right"></i></span>',
			prevArrow: '<span class="slick-arrows slick-prev"><i class="icon icon-arrow-left"></i></span>',
			mobileFirst: true,
			responsive: [
				{
					breakpoint: 1400,
					settings: {
						slidesToShow: 3,
						centerMode: true,
						variableWidth: true,
					}
				},
				{
					breakpoint: 1220,
					settings: {
						slidesToShow: 3,
						centerMode: true,
						variableWidth: true,
					}
				},
				{
					breakpoint: 992,
					settings: {
						slidesToShow: 3,
						centerMode: true,
						variableWidth: true,
					}
				},
				{
					breakpoint: 770,
					settings: {
						slidesToShow: 3,
						centerMode: true,
						variableWidth: true,
					}
				},
				{
					breakpoint: 570,
					settings: {
						slidesToShow: 2,
						centerMode: true,
						variableWidth: true,
					}
				},
				{
					breakpoint: 400,
					settings: {
						slidesToShow: 1,
						centerMode: false,
						variableWidth: false,
					}
				},
				{
					breakpoint: 200,
					settings: {
						slidesToShow: 1,
						centerMode: false,
						variableWidth: false,
					}
				}
			]
		});
	}
}
