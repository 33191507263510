/* ==========================================================================
 *  Function: Add navingation scroll
 * ======================================================================= */

var windowScrollTop;
var windowWidth;

var texturesOffset;
var texturesTop;
var texturesShow = false;

var footerTop;
var footerShow = false;

if ($('.navigation').length){
	var navigation = $('.navigation');
}

if ($('#textures-section').length){
	var texturesSection = $('#textures-section');
}

if ($('#footer').length){
	var footer = $('#footer');
}

function fn_parts_navigation_scroll_resize(){
	windowScrollTop = $(window).scrollTop();
	windowWidth = $(window).width();

	if ($('.navigation').length){
		if (windowScrollTop > 1 && windowWidth > bp_small) {
			navigation.addClass( 'navigation-scroll' );
		} else {
			navigation.removeClass( 'navigation-scroll' );
		}
	}

	if ($('#textures-section').length && !texturesShow && windowWidth > bp_small){
		texturesTop = texturesSection.offset().top;
		if (windowScrollTop > texturesTop  ) {
			fn_components_marquee();
			texturesShow = true;
		}
	}

	if ($('#footer').length && !footerShow && !$( '#contact-map' ).length){
		footerTop = footer.offset().top - 500;
		if (windowScrollTop > footerTop  ) {
			fn_components_google_map();
			footerShow = true;
		}
	}
}
