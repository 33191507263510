/* ==========================================================================
 *  Function: Dropdown
 * ======================================================================= */
function fn_parts_dropdown(){
	if ($( '.js-dropdown-trigger' ).length){
		$( '.js-dropdown-trigger' ).on( 'click' , function () {
			var inst = $(this);
			var thisItem = inst.closest( '.js-dropdown-block' );
			var content = '.js-dropdown-content';
			var dropdownBlock = inst.closest( '.js-dropdown-group' )
			
			if (thisItem.hasClass( class_active )) {
				thisItem.toggleClass( class_active );
			} else {
				dropdownBlock.find( '.js-dropdown-block.' + class_active + '' ).removeClass( class_active );
				inst.closest( '.js-dropdown-block' ).addClass(class_active);
			}
		});
	}

	if ($( '.js-dropdown-block.menu-list-item' ).length && $(window).width() > bp_medium){
		$( '.js-dropdown-block.menu-list-item' ).hover(
			function() {
				$('.modal-overlay').addClass("is-active-navigation");
			}, function() {
				$('.modal-overlay').removeClass("is-active-navigation");
			}
		);
	}
}