//====================================================
//  Function: Redirect
//====================================================
function fn_components_redirect_url(){
	if ($( '.js-redirect-mobile' ).length){
		$( document ).on('click', '.js-redirect-mobile' , function(e){
			if ($(window).width() < 570){
				var inst = $(this);
				var redirectUrl = inst.attr('data-url');
				window.location.replace(redirectUrl);
			}
		});
	}
}
